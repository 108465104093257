import { useAppNNConfig } from '../store/appNNConfig'
import { SMART_LOCKS_BUILDERS } from '../constants/common'
import { useHasSmartLock } from './useHasSmartLock'

export const useEnableLock = () => {
  const { isAdmin, builderId } = useAppNNConfig();
  const { data, isFetched } = useHasSmartLock();

  const isShowLock = isAdmin || builderId === 537; // Show lock activation for specific builder: LampProperties
  const isSmartLock = isAdmin || (builderId && SMART_LOCKS_BUILDERS.includes(builderId));

  return {
    isShowLock,
    isSmartLock: isSmartLock || data?.hasDevice,
    isFetched,
  }
}