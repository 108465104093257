import React, { useEffect } from 'react'
import { Block, Chip, Page } from 'konsta/react'

import { navTo } from '../../browserHistory'
import { ILayout } from '../../components/ILayout'
import HubsIcon from '../../assets/hubs.png'
import IglooIcon from '../../assets/Igloohome.png'
import SmartLockIcon from '../../assets/smart-lock.png'
import { paths } from '../../routes/path'
import { Manufacturer } from '../../constants/property'
import { useEnableLock } from '../../hooks/useEnableLock'

export const ActivateProperty = () => {
  const { isShowLock, isSmartLock, isFetched } = useEnableLock()

  useEffect(() => {
    if (isFetched && !isShowLock && !isSmartLock) {
      navTo(paths.selectCommunity(Manufacturer.Ezlo))
    }
  }, [isFetched])

  if (!isFetched) {
    return <ILayout>
        <Page></Page>
    </ILayout>
  }

  return (
    <ILayout>
      <Page>
        <Block className='p-4'>
          <h1 className='text-xl text-center mt-4'>Select the device you want to use</h1>
          <Block className='grid grid-cols-2 !px-0'>
            {/* First Card: Activate Property */}
            <div
              className='bg-white rounded-md cursor-pointer mt-2 mx-1 relative h-[200px] flex flex-col'
              title='Activate Property'
              onClick={() => navTo(paths.selectCommunity(Manufacturer.Ezlo))}
            >
              <Chip
                className='absolute top-1 right-1 text-xs !px-2'
                colors={{
                  fillBgIos: 'bg-primary',
                  fillTextIos: 'text-white'
                }}
              >
                New
              </Chip>
              <p className='pb-1 p-1 text-center text-sm font-semibold mb-2'>Z-wave Hub</p>
              <img src={HubsIcon} alt='hubs' className='max-w-[150px] mx-auto flex-grow h-full aspect-square' />
            </div>

            {/* Second Card: Smart lock */}
            {isSmartLock && (
              <div
                className='bg-white rounded-md cursor-pointer mt-2 mx-1 relative h-[200px] flex flex-col'
                title='Activate Property'
                onClick={() => navTo('/smart-locks')}
              >
                <Chip
                  className='absolute top-1 right-1 text-xs !px-2'
                  colors={{
                    fillBgIos: 'bg-primary',
                    fillTextIos: 'text-white'
                  }}
                >
                  New
                </Chip>
                <p className='pb-1 p-1 text-center text-sm font-semibold mb-2'>Wifi lock</p>
                <img src={SmartLockIcon} alt='hubs' className='max-w-[150px] mx-auto flex-grow h-[150px]' />
              </div>
            )}

            {/* Third Card: Igloo */}
            {isShowLock && (
              <div
                className='bg-white rounded-md cursor-pointer mt-2 mx-1 relative h-[200px] flex flex-col'
                title='Activate Property'
                onClick={() => navTo(paths.selectCommunity(Manufacturer.IGLOO_IOT))}
              >
                <Chip
                  className='absolute top-1 right-1 text-xs !px-2'
                  colors={{
                    fillBgIos: 'bg-primary',
                    fillTextIos: 'text-white'
                  }}
                >
                  New
                </Chip>
                <p className='pb-1 p-1 text-center text-sm font-semibold mb-2'>Igloo IoT Lock</p>
                <div className='flex flex-grow items-center justify-center'>
                  <img src={IglooIcon} alt='hubs' className='max-w-[150px] mx-auto object-cover aspect-square' />
                </div>
              </div>
            )}
          </Block>
        </Block>
      </Page>
    </ILayout>
  )
}
