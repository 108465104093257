import React from 'react'
import { Block, Button, Chip, Page } from 'konsta/react'
import { FaUserCog, FaPhone, FaHome } from 'react-icons/fa'
import { MdRealEstateAgent } from 'react-icons/md'

import { navTo } from '../../browserHistory'
import { ILayout } from '../../components/ILayout'
import LogoIcon from '../../assets/logo-dark.svg'
import { HELP_CONTACT } from '../../constants/common'
import { BsLightningCharge } from 'react-icons/bs'

export const Home = () => {
  return (
    <ILayout isHome title='NterNow Install Homepage'>
      <Page className='flex flex-col items-center justify-center min-h-screen'>
        {/* Welcome Message */}
        <Block>
          <LogoIcon className='h-auto mx-auto' />
        </Block>

        {/* Cards Layout: 2 in Each Row */}
        <Block className='grid grid-cols-2'>
          {' '}
          {/* Use grid for 2 columns on larger screens */}
          {/* First Card: Manage Hub */}
          <div
            className='bg-white rounded-md cursor-pointer mt-2 mx-1'
            title='Manage Your Hub'
            onClick={() => navTo('/servers')}
          >
            <p className='pb-1 p-1 min-h-28'>
              <FaUserCog size={24} className='mx-auto mb-2 text-green-500' />
              <p className='text-center text-xs'>Pair devices, add codes and control smart home settings</p>
            </p>
            <Button className='w-full !bg-green-500 relative text-xs'>Manage Hub</Button>
          </div>
          {/* Second Card: Activate Property */}
          <div
            className='bg-white rounded-md cursor-pointer mt-2 mx-1 relative'
            title='Activate Property'
            onClick={() => navTo('/activate-property' /*paths.selectCommunity(Manufacturer.Ezlo)*/)}
          >
            <Chip
              className='absolute top-1 right-1 text-xs'
              colors={{
                fillBgIos: 'bg-primary',
                fillTextIos: 'text-white'
              }}
            >
              New
            </Chip>
            <p className='pb-1 p-1 min-h-28'>
              <FaHome size={24} className='mx-auto mb-2 text-primary' />
              <p className='text-center text-xs'>
                Enables a property for tours, builds a geofence, and inserts code automation.
              </p>
            </p>
            <Button className='w-full text-primary relative text-xs'>
              Activate Property <BsLightningCharge />
            </Button>
          </div>
          <div
            className='bg-white rounded-md cursor-pointer mt-2 mx-1 relative'
            title='Manage Property Access and Lock Setup'
            onClick={() => navTo('/property-manage')}
          >
            <Chip
              className='absolute top-1 right-1 text-xs'
              colors={{
                fillBgIos: 'bg-primary',
                fillTextIos: 'text-white'
              }}
            >
              New
            </Chip>
            <p className='pb-1 p-1 min-h-28'>
              <MdRealEstateAgent size={24} className='mx-auto mb-2 text-primary' />
              <p className='text-center text-xs'>
                Updates property status to sold, reset the hub to be moved, and resets the lock.
              </p>
            </p>
            <Button className='w-full relative text-xs'>Mark as Sold & Reset</Button>
          </div>
          {/* Fourth Card: Additional Functionality */}
          <div
            className='bg-white rounded-md cursor-pointer mt-2 mx-1'
            title='Additional Functionality'
            onClick={() => window.open(HELP_CONTACT, '_self')}
          >
            <p className='pb-1 p-1 min-h-28'>
              <FaPhone size={24} className='mx-auto mb-2 text-blue-500' />
              <p className='text-center text-xs'>
                Need assistance?
                <br />
                Speak to us.
              </p>
            </p>
            <Button className='w-full !bg-blue-500 relative text-xs'>Live help</Button>
          </div>
        </Block>
      </Page>
    </ILayout>
  )
}
