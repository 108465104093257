import { Preloader } from 'konsta/react'
import React from 'react'

export const GlobalLoading = ({ loading, text }: { loading: boolean; text?: string }) => {
  if (!loading) return null

  return (
    <div className='bg-[#000000bd] z-[200] flex-col gap-2 text-primary text-lg bg-opacity-50 absolute text-center h-full w-full flex items-center justify-center'>
      <Preloader /> <span className='font-bold'>{text}</span>
    </div>
  )
}
